<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">工单处理</h1>
    </div>
    <div slot="extra">
      <a-button class="m-r-12" icon="plus" type="primary" @click="handleAdd">创建工单</a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 20px;">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="处理状态">
              <a-radio-group @change="selectStatus" v-model="searchParams.status">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="UN_PROCESS">待处理</a-radio-button>
                <a-radio-button value="PROCESSED">已处理</a-radio-button>
                <a-radio-button value="FINISHED">已完成</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="工单名称">
              <a-input v-model="searchParams.title" placeholder="请输入工单名称" allowClear/>
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="工单来源">
              <a-select allowClear showSearch
                        v-model="searchParams.ticketSource"
                        placeholder="请选择工单来源"
                        @change="search">
                <a-select-option
                  v-for="(item, index) in tickSourceList"
                  :key="index"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-row :gutter="12">
          <a-spin :spinning="spinning">
            <a-col :sm="24" :md="12" :lg="6" :xl="6">
              <a-card>
                <a-statistic
                  title="总工单"
                  :value="statisticsData.totalNumbers"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="6" :xl="6">
              <a-card>
                <a-statistic
                  title="已完成"
                  :value="statisticsData.finishedNumbers"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="6" :xl="6">
              <a-card>
                <a-statistic
                  title="已处理"
                  :value="statisticsData.processedNumbers"
                  :valueStyle="valueStyle"
                  style="text-align: center; color: #1890ff;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="6" :xl="6">
              <a-card>
                <a-statistic
                  title="待处理"
                  :value="statisticsData.unProcessNumbers"
                  :valueStyle="valueStyle"
                  style="text-align: center; color: red;"
                >
                </a-statistic>
              </a-card>
            </a-col>
          </a-spin>
        </a-row>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        bordered
        :scroll="{ x: 1400 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="ticketSource" slot-scope="text, record">
          <a-tag v-if="record.ticketSource==='AUTO'" color="#B452CD">自动生成</a-tag>
          <a-tag v-if="record.ticketSource==='MANUAL'" color="#CD8500">人工创建</a-tag>
          <a-tag v-if="record.ticketSource==='REPORT'" color="#008B8B">电话调度</a-tag>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status==='UN_PROCESS'" color="blue" >待处理</a-tag>
          <a-tag v-if="record.status==='PROCESSED'" color="#15da8e"> {{record.currentProcessUser}}处理中</a-tag>
          <a-tag v-if="record.status==='FINISHED'" color="#1874CD">已完成</a-tag>
        </template>
        <template slot="startProcessTime" slot-scope="text, record">
          <span>{{record.startProcessTime | formatDate}}</span>
        </template>
        <template slot="endProcessTime" slot-scope="text, record">
          <span>{{record.endProcessTime | formatDate}}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.status==='UN_PROCESS'" @click="assignClick(record.id)" @click.stop>派单</a>
          <span v-else style="color: #babec1">已派单</span>
          <a-divider v-if="record.ticketSource !=='AUTO'" type="vertical" />
          <a v-if="record.ticketSource !=='AUTO' "
             @click="handleDelete(record.id)" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="创建工单" v-model="addModal" width="560px">
      <add-form ref="addRef" @addSuccess="addSuccess" @addError="addError"></add-form>
      <template slot="footer">
        <a-button @click="closeAddModal">取消</a-button>
        <a-button :loading="addLoading" @click="addOk" type="primary" style="margin-right: 5px;">创建</a-button>
      </template>
    </a-modal>
    <!--指派Modal-->
    <a-modal title="派单" v-model="assignModal" width="560px">
      <assign ref="assignRef" @assignSuccess="assignSuccess" @assignError="assignError"></assign>
      <template slot="footer">
        <a-button :loading="assignLoading" @click="assignOk" type="primary" style="margin-right: 5px;">派单</a-button>
        <a-button @click="closeAssignModal">取消</a-button>
      </template>
    </a-modal>
    <!--处理Modal-->
    <a-modal title="工单处理" v-model="handleModal" width="560px">
      <handle ref="handleRef" @handleSuccess="handleSuccess" @handleError="handleError"></handle>
      <template slot="footer">
        <a-button :loading="handleLoading" @click="handleOk" type="primary" style="margin-right: 5px;">处理</a-button>
        <a-button @click="closeHandleModal">取消</a-button>
      </template>
    </a-modal>
    <a-drawer
      title="工单详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="550px"
      :visible="detailDrawer"
      :mask="false">
      <ticket-info-detail ref="viewRef"></ticket-info-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { ticketInfoColumns, processStatusList, ticketTypeList, tickSourceList } from './common/common'
  import TicketInfoDetail from '../info/Detail'
  import Handle from '../info/Handle'
  import AddForm from './Form'
  import Assign from './Assign'
  import moment from 'moment'

  export default {
    name: 'MyTicketInfoList',
    components: { PageLayout, TicketInfoDetail, Handle, AddForm, Assign },
    filters: {
      formatDate (date) {
        if (date) {
          return moment(date).format('YYYY-MM-DD HH:mm:ss')
        } else {
          return '~'
        }
      }
    },
    data () {
      return {
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        spinning: true,
        statisticsData: {},
        searchUrl: SERVICE_URLS.ticket.info.search,
        deleteUrl: SERVICE_URLS.ticket.info.delete,
        detailDrawer: false,
        addModal: false,
        addLoading: false,
        assignModal: false,
        assignLoading: false,
        handleModal: false,
        handleLoading: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        tableData: [],
        columns: ticketInfoColumns(),
        searchParams: {
          page: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          status: 'UN_PROCESS',
          ticketSource: undefined
        },
        selectedRowKeys: [],
        columnsList: [],
        tableLoading: false,
        processStatus: processStatusList(),
        ticketTypeList: ticketTypeList(),
        tickSourceList: tickSourceList(),
        orgTree: [],
        ticketId: '' //当前处理工单id
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.search()
    },
    created () {
      this.getOrgTree()
      this.getStatisticsData()
    },
    methods: {
      selectStatus () {
        this.search()
      },
      selectType () {
        this.search()
      },
      getStatisticsData () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.info.statistic,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
            this.spinning = false
          }
        })
      },
      //创建工单
      handleAdd () {
        this.addModal = true
      },
      addOk () {
        this.$nextTick(() => {
          this.$refs.addRef.addSubmit()
        })
      },
      closeAddModal () {
        this.addModal = false
      },
      //指派工单
      assignClick (id) {
        this.assignModal = true
        this.ticketId = id
      },
      assignOk () {
        this.$nextTick(() => {
          this.$refs.assignRef.assign(this.ticketId)
        })
      },
      assignSuccess () {
        this.assignModal = false
        this.$message.warning('派单完成')
        this.getStatisticsData()
        this.search()
      },
      assignError () {
        this.assignModal = false
        this.$message.warning('派单失败')
      },
      closeAssignModal () {
        this.assignModal = false
      },
      search () {
        this.$http(this, {
          url: this.searchUrl,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.page = data.body.number
            this.getStatisticsData()
          }
        })
      },
      handleClick (id) {
        this.handleModal = true
        this.handleId = id
      },
      handleOk () {
        this.$nextTick(() => {
          this.$refs.handleRef.handle(this.handleId)
        })
      },
      addSuccess () {
        this.addModal = false
        this.search()
      },
      addError () {
        this.addModal = false
        this.$message.warning('创建失败')
      },
      closeHandleModal () {
        this.handleModal = false
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      /*表格页数发生变化*/
      tableChange (pagination, filters, sorter) {
        // this.searchParams.size = pagination.pageSize
        // this.searchParams.number = pagination.current - 1
        this.setSearchParamsPage(pagination)
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.search()
      },
      setSearchParamsPage (pagination) {
        this.searchParams.page = pagination.current - 1
        this.searchParams.size = pagination.pageSize
      },
      handleSuccess () {
        this.handleModal = false
        this.$message.warning('处理完成')
        this.search()
      },
      handleError () {
        this.handleModal = false
        this.$message.warning('处理失败')
      },
      resetForm () {
        this.searchParams = {
          page: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          ticketProcessStatus: 'UN_PROCESS',
          ticketType: ''
        }
        this.search()
      },
      /*删除*/
      handleDelete (id, content) {
        if (!this.deleteUrl) {
          console.error('请在api中定义\'delete\'')
          return
        }

        const _this = this
        this.$confirm({
          title: '确认删除操作?',
          content: content || '此数据将会被删除',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk () {
            return new Promise((resolve, reject) => {
              _this.$http(_this, {
                url: _this.deleteUrl,
                params: {
                  id: id
                },
                loading: 'tableLoading',
                noTips: false,
                successTipsContent: '数据删除成功!',
                success: () => {
                  _this.search()
                  resolve()
                }
              })
            }).catch(() => console.log('Oops delete errors!'))
          },
          onCancel () {
          }
        })
      }
    }
  }
</script>

<style scoped></style>